<template>
	<div class="table">
		<div class="container"  >
			<el-form :inline="true" :model="searchForm" ref="searchForm">
				<el-row>
					<el-form-item label="医生名称">
						<el-input v-model.trim="searchForm.doctorName" size="small" placeholder="请输入医生名称"></el-input>
					</el-form-item>
					<el-form-item label="诊所名称">
						<el-input v-model.trim="searchForm.hospitalId" size="small" placeholder="请输入诊所名称"></el-input>
					</el-form-item>
					<!-- <el-form-item prop="deptId" label="所属科室">
						<el-select v-model="searchForm.dept_name" size="small" filterable clearable placeholder="请选择"
							style="width: 220px;">
							<el-option v-for="(item,index) in deptSeOptions" :key="index" :label="item.dept_name"
								:value="item.dept_name">
							</el-option>
						</el-select>
					</el-form-item> -->

					<el-form-item label="职称评级" prop="level">
						<el-select v-model.trim="searchForm.level" placeholder="请选择职称评级" size="small"
							style="width: 200px;">
							<el-option v-for="(item,index) in levels" :key="index" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="审核状态">
						<el-select v-model="searchForm.is_audit" size="small" clearable placeholder="请选择审核状态"
							style="width: 200px;">
							<el-option v-for="item in auditOptions" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
          <el-form-item label="审核说明">
            <el-input v-model.trim="searchForm.audit_spec" size="small" placeholder="请输入审核说明"></el-input>
          </el-form-item>
					<el-form-item label="医助">
						<el-input v-model.trim="searchForm.referrerName" size="small" placeholder="请输入医助"></el-input>
					</el-form-item>
					</br>
					<el-form-item label="所在地区省">
						<el-cascader placeholder="请输入所在地区省" class="selectWdith" size="small" :options="options"
							v-model="searchForm.area" @change="changeArea" :props="{ expandTrigger: 'hover',checkStrictly: true }">
						</el-cascader>
					</el-form-item>

					<el-form-item prop="order_state" label="注册时间">
						<dateChoose v-model="searchForm.time" :time="searchForm.time" width="220px" dateType="11">
						</dateChoose>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" plain icon="el-icon-search" size="small"
							@click="getData()">查询</el-button>
						<el-button type="warning" plain icon="el-icon-refresh" size="small"
							@click="reset()">重置</el-button>
						<!-- <el-button type="success" plain size="small" icon="el-icon-plus"
							@click="showAddVisible('add')">新增</el-button> -->
						<el-button type="primary" plain size="small" icon="el-icon-upload2"
							@click="exportFile">导出</el-button>

					</el-form-item>
				</el-row>
			</el-form>
			<el-table v-loading="loading" ref="table" highlight-current-row @current-change="handleCurrentChangeT"
				  :data="tableData" border stripe size="mini" style="width: fit-content;">
				<el-table-column prop="hospitalId" label="所属诊所" width="130" show-overflow-tooltip></el-table-column>
				<el-table-column prop="area" label="所属地区" width="180" show-overflow-tooltip></el-table-column>
				<el-table-column prop="id" label="医生ID" width="80" show-overflow-tooltip></el-table-column>
				<el-table-column prop="doctorName" label="医生姓名" width="80" show-overflow-tooltip></el-table-column>
				<!-- <el-table-column prop="deptId" label="科室" width="100" show-overflow-tooltip></el-table-column> -->
				<el-table-column prop="sex" label="性别" width="50" show-overflow-tooltip></el-table-column>
				<el-table-column prop="phone" label="手机号码" width="100" show-overflow-tooltip></el-table-column>
				<el-table-column prop="level" label="职称级别" width="80" show-overflow-tooltip></el-table-column>
				<el-table-column prop="workYearStr" label="执医年限" width="70" show-overflow-tooltip></el-table-column>
				<el-table-column prop="createTime" label="注册时间" width="150" show-overflow-tooltip></el-table-column>
				<el-table-column prop="createUser" label="审核人" width="80" show-overflow-tooltip></el-table-column>
				<el-table-column prop="audit_time" label="审核时间" width="150" show-overflow-tooltip></el-table-column>
				<el-table-column prop="is_audit" label="审核结果" width="100" show-overflow-tooltip></el-table-column>
				<el-table-column prop="audit_spec" label="审核说明" width="170" show-overflow-tooltip></el-table-column>
				<el-table-column prop="referrerName" label="业务员" width="100" show-overflow-tooltip></el-table-column>
				<el-table-column prop="lastTime" label="最后登录时间" width="180" show-overflow-tooltip></el-table-column>
				<el-table-column prop="" label="操作" width="260" fixed="right">
					<template slot-scope="scope">
						<el-button size="mini" type="warning" icon="el-icon-document" plain
							@click="auditVisible(scope.row)" :loading="scope.row.editLoading"
							:disabled="scope.row.is_audit == '审核通过'">审核</el-button>
						<el-button size="mini" type="primary" icon="el-icon-edit" plain
							@click="showAddVisible('edit',scope.row)" :loading="scope.row.editLoading">编辑</el-button>
						<el-button size="mini" type="danger" plain icon="el-icon-delete"
							@click="deleteStation(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--TODO 分页-->
			<div class="pagination table-width-b" v-if="totalPage!=0" :style="'width:'+footWidth+';max-width:100%;'">
				<el-pagination @size-change="handleSizeChange" :page-sizes="[10,20,50]" :page-size="searchForm.limit"
					:current-page.sync="searchForm.currentPage" @current-change="handleCurrentChange"
					layout="jumper, prev, pager, next, sizes, total" :total="total">
				</el-pagination>
			</div>
			<!--新增/修改弹框-->
			<el-dialog :close-on-click-modal="false" :title='titleName' v-if="addVisible" :visible.sync="addVisible"
				width="700px" top="20vh" :append-to-body="true" :before-close="noClick">
				<el-form :inline="true" ref="addForm" :model="addForm" label-width="100px" :rules="addFormRules">
					<el-form-item label="医生名称" prop="doctorName">
						<el-input v-model.trim="addForm.doctorName" size="small" placeholder="请输入医生名称"></el-input>
					</el-form-item>
					<el-form-item label="性别" prop="sex">
						<el-select v-model.trim="addForm.sex" placeholder="请选择性别" size="small" class="selectWdith">
							<el-option v-for="(item,index) in sexs" :key="index" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="所在地区" prop="province">
						<el-cascader placeholder="请输入所在地区" class="selectWdith" size="small" :options="options"
							v-model="addForm.addrArry" @change="handleChangeAddr" :separator="' '"
							:props="{ expandTrigger: 'hover' }" disabled>
						</el-cascader>
					</el-form-item>
					<el-form-item prop="hospitalId" label="所属诊所">
							<el-input v-model.trim="addForm.hospitalId" size="small" placeholder="请输入诊所名称" disabled></el-input>
					</el-form-item>
					<el-form-item prop="addr" label="收货地址">
							<el-input style="width:510px;" v-model.trim="addForm.addr" size="small" placeholder="请输入收入地址" disabled></el-input>
					</el-form-item>

					<el-form-item label="手机号码" prop="phone">
						<el-input v-model.trim="addForm.phone" size="small" placeholder="请输入手机号码"></el-input>
					</el-form-item>
					<el-form-item label="身份证号" prop="did_card">
						<el-input v-model.trim="addForm.did_card" size="small" placeholder="请输入身份证号"></el-input>
					</el-form-item>
					<el-form-item label="职称评级" prop="level">
						<el-select v-model.trim="addForm.level" placeholder="请选择职称评级" size="small" class="selectWdith">
							<el-option v-for="(item,index) in levels" :key="index" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="从医年限" prop="workYearStr">
						<el-input v-model.trim="addForm.workYearStr" size="small"
							oninput="value=value.replace(/[^\d]/g,'')" placeholder="请输入从医年限"></el-input>
					</el-form-item>
					<el-form-item label="推介人">
						<el-select filterable v-model="addForm.referrerId" placeholder="请选择推荐人" class="selectWdith" size="small">
							<el-option v-for="item in referrerOptions" :key="item.id"
								:label="item.name + '[' + item.phone + ']'" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="医生简介">
						<el-input v-model.trim="addForm.introduct" type="textarea" :rows="5" maxlength="500"
							size="small" placeholder="请输入医生简介，限制500字符以内" style="width: 510px;"></el-input>
					</el-form-item>
					<el-form-item label="执业证书">
						<div style="width:510px;display:flex;">
							<div style="margin:0 10px; cursor: pointer;" v-for="(item,index) in fileList1" :key="index">
								<el-image
								style="width: 100px; height: 100px"
								:src="item.url"
								:preview-src-list="[item.url]"
								>
								</el-image>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="资质证书">
						<div style="width:510px;display:flex;">
							<div style="margin:0 10px;cursor: pointer;" v-for="(item,index) in fileList2" :key="index">
								<el-image
								style="width: 100px; height: 100px"
								:src="item.url"
								:preview-src-list="[item.url]"
								>
								</el-image>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="电子签名">
						<div style="width:510px;display:flex;">
							<div style="margin:0 10px;cursor: pointer;" v-for="(item,index) in fileList3" :key="index">
								<el-image
								style="width: 100px; height: 100px"
								:src="item.url"
								:preview-src-list="[item.url]"
								>
								</el-image>
							</div>
						</div>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button size="small" @click="noClick()">取&nbsp;消</el-button>
					<el-button type="primary" size="small" @click="saveEdit('addForm',type)">保&nbsp;存</el-button>
				</span>
			</el-dialog>
			<!--图片预览弹窗-->
			<el-dialog :close-on-click-modal="false" title='图片预览' v-if="showBigImg" :visible.sync="showBigImg"
				width="600px" top="20vh" :append-to-body="true">
				<img :src="showBigImgUrl" width="100%">
			</el-dialog>
			<!--审核弹窗-->
			<el-dialog :close-on-click-modal="false" :title='titleName' v-if="isAuditVisible"
				:visible.sync="isAuditVisible" width="600px" top="20vh" :append-to-body="true" :before-close="noClick1">
				<el-form ref="auditForm" :model="auditForm" label-width="100px" :rules="auditFormRules">
					<el-form-item label="是否通过" prop="is_audit">
						<el-radio-group v-model="auditForm.is_audit">
							<el-radio size="small" label="2" border @change="handleIsAduit">审核通过</el-radio>
							<el-radio size="small" label="1" border @change="handleIsAduit">审核未通过</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item prop="audit_script" label="审核话术">
						<el-autocomplete class="inline-input" v-model="auditForm.audit_script"
							:fetch-suggestions="querySearch" placeholder="请输入内容" size="small" style="width: 246px;"
							@select="handleSelect"></el-autocomplete>
					</el-form-item>
					<el-form-item label="审核说明" prop="audit_spec">
						<el-input v-model.trim="auditForm.audit_spec" type="textarea" :rows="5" maxlength="500"
							size="small" placeholder="请输入审核说明，限制500字符以内" style="width: 246px;"></el-input>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button size="small" @click="noClick1()">取&nbsp;消</el-button>
					<el-button type="primary" size="small" @click="isAudit('auditForm')">保&nbsp;存</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>
<script>
import BaseUrl from "@/utils/baseUrl";
import regulars from "@/utils/regulars.js";
import options from "@/utils/country-data.js";
import dateChoose from "@/components/date-choose";
import moment from "moment";

export default {
  components: {
    dateChoose,
  },
  created() {},
  watch: {
    "searchForm.time": {
      handler(newName, oldName) {
        this.searchForm.start_time = newName[0];
        this.searchForm.end_time = newName[1];
        //执行代码
      },
      deep: true,
    },
  },
  data() {
    ////校验手机号，号段主要有(不包括上网卡)：130~139、150~153，155~159，180~189、170~171、    176~178。14号段为上网卡专属号段
    const telReg =
      /^((0\d{2,3}-\d{7,8})|(((13[0-9])|(14[0-9])|(15[0-9])|(16[0-9])|(17[0-9])|(18[0-9])|(19[0-9]))\d{8}))|400[0-9]{7}$/;
    var telValue = regulars.checkRegular(
      telReg,
      "请输入手机号码",
      "手机号码输入不合法!"
    );
    const personIDReg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    var personIDValue = regulars.checkRegular2(
      personIDReg,
      "身份证号码输入不合法"
    );
    //校验手机号重复性
    var checkRepeat = (rule, value, callback) => {
      if (value == "" || value == null) {
        callback(new Error("请输入手机号码"));
      } else {
        this.$http
          .post(BaseUrl.checkDoctorPhone, {
            id: this.addForm.id,
            phone: this.addForm.phone,
          })
          .then((res) => {
            if (res.data.code == 0) {
              if (res.data.data == 0) {
                callback();
              } else {
                callback(new Error("当前手机号码重复"));
              }
            } else {
              callback(new Error("手机号码校验失败"));
            }
          });
      }
    };
    return {
      fileList1: [],
      fileList2: [],
      fileList3: [],
      showBigImg: false,
      showBigImgUrl: "",
      footWidth: "0px",
      loading: true, //列表loading
      tableData: [],
      searchForm: {
        hospital_name: "",
        doctorName: "",
        limit: limit,
        currentPage: 1,
        start_time: "",
        end_time: "",
        time: [],
        referrerName: "",
        area: null,
        level: null,
        is_audit: null,
        audit_spec:null
      },
      sexs: [
        {
          name: "男",
          id: "男",
        },
        {
          name: "女",
          id: "女",
        },
        {
          name: "保密",
          id: "保密",
        },
      ],
      levels: [
        {
          name: "主任医师",
          id: "主任医师",
        },
        {
          name: "副主任医师",
          id: "副主任医师",
        },
        {
          name: "主治医师",
          id: "主治医师",
        },
        {
          name: "医师",
          id: "医师",
        },
      ],
      auditOptions: [
        {
          label: "未审核",
          value: "0",
        },
        {
          label: "审核未通过",
          value: "1",
        },
        {
          label: "审核通过",
          value: "2",
        },
      ],
      total: 0,
      totalPage: 0,
      titleName: "",
      addVisible: false,
      isAuditVisible: false,
      addForm: {},
      auditForm: {
        is_audit: "",
        audit_script: "",
        audit_spec: "",
      },
      addFormRules: {
        // hospitalId:[
        //     { required: true, message: "请选择诊所", trigger: "blur" }
        // ],
        // deptId:[
        //     { required: true, message: "请选择科室", trigger: "blur" }
        // ],
        doctorName: [
          {
            required: true,
            message: "请输入医生名称",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            trigger: "blur",
            validator: telValue,
          },
          {
            required: true,
            trigger: "blur",
            validator: checkRepeat,
          },
        ],
        did_card: [
          {
            required: false,
            trigger: "blur",
            validator: personIDValue,
          },
        ],
        workYearStr: [
          {
            required: true,
            message: "请输入从医年限",
            trigger: "blur",
          },
        ],
        /*level:[
					    { required: true, message: "请输入职称评级", trigger: "blur" }
					]*/
      },
      auditFormRules: {
        is_audit: [
          {
            required: true,
            message: "请选择是否通过",
            trigger: "[blur,change]",
          },
        ],
        audit_script: [
          {
            required: true,
            message: "请填写审核话术",
            trigger: "[blur,change]",
          },
        ],
        audit_spec: [
          {
            required: true,
            message: "请填写审核说明",
            trigger: "blur",
          },
        ],
      },
      type: "", //add,edit
      row: null,
      deptOptions: [], //组织机构树
      deptSeOptions: [],
      aduitOptions: [],
      restaurants: [],
      deptInfo: {
        id: "",
        name: "",
      },
      referrerOptions: [],
      hospitalOptions: [],
      options: options,
    };
  },
  mounted() {
    this.getSeDeptOptions();
    var that = this;
    this.searchForm.start_time = this.searchForm.time[0];
    this.searchForm.end_time = this.searchForm.time[1];
    document.getElementsByClassName("content")[0].onclick = function () {
      if (that.currentChange) {
        that.currentChange = false;
      } else {
        that.setCurrent();
      }
    };
    that.footWidth = that.$refs.table.bodyWidth;
    console.log(this.searchForm.time);
    this.searchForm.start_time = this.searchForm.time[0];
    this.searchForm.end_time = this.searchForm.time[1];
    this.getData();
  },
  methods: {
    //图片预览
    showBigImgFn(url) {
      console.log(url);
      this.showBigImg = true;
      this.showBigImgUrl = url;
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return restaurant.value.indexOf(queryString) > -1;
      };
    },
    handleSelect(item) {
      console.log(item);
      this.auditForm.audit_script = item.audit_script;
    },
    handleHospital(val) {
      this.addForm.deptId = "";
      this.deptOptions = [];
      if (val != "") {
        this.getDeptOptions();
      }
    },
    handleIsAduit(val) {
      let that = this;
      this.auditForm.audit_script = "";
      this.aduitOptions = [];
      console.log(val);
      if (val != "") {
        // this.restaurants = this.getAuditOptions();
        this.getAuditOptions().then((res) => {
          that.restaurants = res;
        });
      }
    },
    getAuditOptions() {
      return new Promise((resolve, reject) => {
        this.$http
          .post(BaseUrl.getAuditSelect, {
            id: this.auditForm.is_audit,
          })
          .then((res) => {
            if (res.data.code == 0) {
              this.aduitOptions = res.data.data;
              let restaurants = res.data.data.map((item) => {
                item.value = item.audit_script;
                return item;
              });
              resolve(restaurants);
            } else {
              this.$message({
                message: res.data.msg,
                type: "warning",
              });
            }
          });
      });
    },
    handleDept() {
      //render函数没有自动更新，需手动强制刷新
      //不加这个，导致选中科室，框里没有回显，需要改变其他框的值才能正常显示
      this.$forceUpdate();
    },
    //更改所属区域
    handleChangeAddr(value) {
      this.addForm.addrArry = value;
      // this.addForm.hospitalId = '';
      // this.hospitalOptions = [];

      this.getHospitalOptions("", this.addForm.addrArry);
    },
    getHospitalOptions(hospital_name, addrArry) {
      this.$http
        .post(BaseUrl.getHospitalSelectByArea, {
          hospital_name: hospital_name,
          province: addrArry[0],
          city: addrArry[1],
          county: addrArry[2],
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.hospitalOptions = res.data.data;
          }
        });
    },
    getSeDeptOptions() {
      this.$http.post(BaseUrl.getDept).then((res) => {
        if (res.data.code == 0) {
          this.deptSeOptions = res.data.data;
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    getDeptOptions() {
      this.$http.post(BaseUrl.getDeptSelect).then((res) => {
        if (res.data.code == 0) {
          this.deptOptions = res.data.data;
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    getReferrerOptions() {
      this.$http.post(BaseUrl.getReferrerList).then((res) => {
        if (res.data.code == 0) {
          this.referrerOptions = res.data.data;
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    setCurrent(row) {
      //取消单选
      //this.$refs.table.setCurrentRow(row);
    },
    handleCurrentChangeT(row, index) {
      this.currentChange = true;
      this.$refs.table.setCurrentRow(row);
    },
    // 更改省市区县查询参数
    changeArea(areaList) {
      console.log("areaList: ", areaList);
      this.$set(this.searchForm, "province", areaList[0]);
      this.$set(this.searchForm, "city", areaList[1]);
      this.$set(this.searchForm, "county", areaList[2]);
    },
    getData() {
      this.loading = true; //列表loading
      let data = new Object();
      data.hospitalId = this.searchForm.hospitalId;
      data.deptId = this.searchForm.deptId;
      data.doctorName = this.searchForm.doctorName;
      data.limit = this.searchForm.limit;
      data.currentPage = this.searchForm.currentPage;
      data.start_time = this.searchForm.start_time;
      data.end_time = this.searchForm.end_time;
      data.time = this.searchForm.time;
      data.referrerName = this.searchForm.referrerName;
      data.province = this.searchForm.province;
      data.city = this.searchForm.city;
      data.county = this.searchForm.county;
      data.level = this.searchForm.level;
      data.is_audit = this.searchForm.is_audit;
      data.audit_spec = this.searchForm.audit_spec;
      this.$http.post(BaseUrl.getDoctorPageLimit, data).then((res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data.data;
          this.totalPage = res.data.data.totalPage;
          this.total = res.data.data.total;
          this.loading = false; //列表loading
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    handleSizeChange(val) {
      this.searchForm.limit = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.searchForm.currentPage = val;
      this.getData();
    },
    reset() {
      this.resetSearchForm(this.searchForm, [
        {
          k: "limit",
          v: this.searchForm.limit,
        },
        {
          k: "currentPage",
          v: this.searchForm.currentPage,
        },
        {
          k: "start_time",
          v: moment().subtract(7, "days").format("YYYY-MM-DD"),
        },
        {
          k: "end_time",
          v: moment().format("YYYY-MM-DD"),
        },
        {
          k: "time",
          v: [
            moment().subtract(7, "days").format("YYYY-MM-DD"),
            moment().format("YYYY-MM-DD"),
          ],
        },
        {
          k: "area",
          v: null,
        },
        {
          k: "referrerName",
          v: null,
        },

      ]);
      // this.getData();
    },
    auditVisible(row) {
      this.aduitOptions = [];
      this.titleName = "审核医生信息";
      this.row = row;
      this.$http
        .post(BaseUrl.getDoctorInfo, {
          id: row.id,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.auditForm = res.data.data;
            if (this.auditForm.is_audit == "0") {
              this.auditForm.is_audit = "1";
            }
            this.getAuditOptions().then((res) => {
              this.restaurants = res;
            });
            this.isAuditVisible = true;
          } else {
            this.$message({
              message: res.data.msg,
              type: "warning",
            });
          }
        });
      console.log(row);
    },
    showAddVisible(type, row) {
      this.getReferrerOptions();
      //this.getDeptOptions();
      this.addForm = {};
      this.deptOptions = [];
      this.fileList1 = [];
      this.fileList2 = [];
      this.fileList3 = [];
      this.hospitalOptions = [];
      this.addForm.addrArry = [];
      this.type = type;
      if (type == "add") {
        this.titleName = "新增医生信息";
        this.addVisible = true;
      } else {
        this.titleName = "修改医生信息";
        this.row = row;
        this.$set(row, "editLoading", true);
        this.$http
          .post(BaseUrl.getDoctorInfo, {
            id: row.id,
          })
          .then((res) => {
            if (res.data.code == 0) {
              this.addForm = res.data.data;
              this.addForm.addrArry = [
                res.data.data.province,
                res.data.data.city,
                res.data.data.county,
              ];

              this.addVisible = true;
              this.$set(row, "editLoading", false);
              for (let i = 0; i < res.data.data.certList.length; i++) {
                let item = res.data.data.certList[i];
                if (item.cert_num <= 2) {
                  this.fileList1.push({
                    url: item.cert_url,
                    status: "",
                    message: "",
                    num: item.cert_num,
                  });
                } else if (item.cert_num >= 5) {
                  this.fileList3.push({
                    url: item.cert_url,
                    status: "",
                    message: "",
                    num: item.cert_num,
                  });
                } else {
                  this.fileList2.push({
                    url: item.cert_url,
                    status: "",
                    message: "",
                    num: item.cert_num,
                  });
                }
              }
            } else {
              this.$message({
                message: res.data.msg,
                type: "warning",
              });
            }
          });
      }
    },
    isAudit(form) {
      let obj = {
        id: this.row.id,
        isAduit: this[form].is_audit,
        auditScript: this[form].audit_script,
        auditSpec: this[form].audit_spec,
      };
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.$http.post(BaseUrl.isAuditDoctor, obj).then((res) => {
            if (res.data.code == 0) {
              this.titleName = "";
              this.getData();
              this.isAuditVisible = false;
              this.$message({
                message: res.data.msg,
                type: "success",
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: "warning",
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //保存新增/修改
    saveEdit(form, type) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          delete this[form].dept_name;
          if (type == "add") {
            this.$http.post(BaseUrl.insertDoctor, this[form]).then((res) => {
              if (res.data.code == 0) {
                this.titleName = "";
                this.getData();
                this.addVisible = false;
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "warning",
                });
              }
            });
          } else {
            this.addForm.id = this.row.id;
            let form = JSON.parse(JSON.stringify(this.addForm));
            delete form.certList;
            this.$http.post(BaseUrl.updateDoctor, form).then((res) => {
              if (res.data.code == 0) {
                this.titleName = "";
                this.getData();
                this.addVisible = false;
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "warning",
                });
              }
            });
          }
        }
      });
    },
    deleteStation(row) {
      var that = this;
      this.$confirm(
        '是否确认删除医生"' + row.doctorName + '"的数据项?',
        "警告",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(function () {
          that.$http
            .post(BaseUrl.deleteDoctor, {
              id: row.id,
            })
            .then((res) => {
              if (res.data.code == 0) {
                that.getData();
                that.$message({
                  message: "删除成功",
                  type: "success",
                });
              } else {
                that.$message({
                  message: res.data.msg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    noClick() {
      this.getData();
      this.addVisible = false;
    },
    noClick1() {
      this.getData();
      this.isAuditVisible = false;
    },
    /**
     * 导出
     */
    exportFile() {
      let obj = this.searchForm;
      this.$http
        .postFile(BaseUrl.doctorExport, obj, {
          responseType: "arraybuffer",
        })
        .then((res) => {
          var blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;charset=utf-8",
          });
          var downloadElement = document.createElement("a");
          var objectUrl = URL.createObjectURL(blob);
          downloadElement.href = objectUrl;
          downloadElement.download = "医生信息报表.xls"; //下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); //点击下载
          document.body.removeChild(downloadElement); //下载完成移除元素
          window.URL.revokeObjectURL(objectUrl); //释放掉blob对象
        })
        .catch(function (res) {});
    },
  },
};
</script>
<style scoped>
.selectWdith {
  width: 200px;
}
</style>
