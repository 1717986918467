<template>
	<div class="table salespersonPage">
		<div class="container"  >
			<el-form :inline="true" :model="searchForm" ref="searchForm">
				<el-form-item label="人员姓名">
					<el-input v-model.trim="searchForm.username" size="small" placeholder="请输入人员姓名"></el-input>
				</el-form-item>
				<el-form-item label="手机号">
					<el-input v-model.trim="searchForm.phone" size="small" placeholder="请输入手机号"></el-input>
				</el-form-item>

				<!-- <el-form-item label="业务级别" prop="level">
					<el-select v-model.trim="searchForm.level" placeholder="请选择业务级别" size="small" style= "width: 190px;">
                        <el-option label='全部' value=''></el-option>
						<el-option
							v-for="(item,index) in levelOption"
							:key="index"
							:label="item.level_name"
							:value="item.level">
						</el-option>
					</el-select>
			    </el-form-item> -->

				<el-form-item>
					<el-button type="primary" plain icon="el-icon-search" size="small" @click="getData()">查询</el-button>
					<el-button type="warning" plain icon="el-icon-refresh" size="small" @click="reset()">重置</el-button>
					<el-button type="success" plain size="small" icon="el-icon-plus"
						@click="showAddVisible('add')">新增</el-button>
					<el-button type="primary" plain size="small" icon="el-icon-upload2"
						@click="exportFile()">导出</el-button>
				</el-form-item>
			</el-form>
			<el-table v-loading="loading" ref="table" :height="(tableH+40)+'px'" :data="tableData" row-key="id" border
				default-expand-all size="mini" style="width: fit-content;"
				:tree-props="{children: 'children', hasChildren: 'hasChildren'}">
				<!-- <el-table-column prop="id" label="工号" width="100" show-overflow-tooltip></el-table-column> -->
				<el-table-column prop="username" label="人员姓名" width="140" show-overflow-tooltip></el-table-column>
				<el-table-column prop="sex" label="性别" width="50" show-overflow-tooltip></el-table-column>
				<el-table-column prop="phone" label="手机号" width="140" show-overflow-tooltip></el-table-column>
				<el-table-column prop="range" label="区域范围" width="240" show-overflow-tooltip></el-table-column>
				<el-table-column prop="range_code" label="区域代码" width="200" show-overflow-tooltip></el-table-column>
				<el-table-column prop="promCount" label="推广明细" width="80" show-overflow-tooltip>
					<template slot-scope="scope">
						<el-button v-if="scope.row.level!=3" size="mini" type="text"
							@click="getAlertData(scope.row,1)">{{scope.row.promCount}}人</el-button>
						<span v-else>/</span>
					</template>
				</el-table-column>
				<el-table-column prop="doctorCount" label="邀请明细" width="80" show-overflow-tooltip>
					<template slot-scope="scope">
						<el-button v-if="scope.row.level!=1" size="mini" type="text"
							@click="getAlertData(scope.row,2)">{{scope.row.doctorCount}}人</el-button>
						<span v-else>/</span>
					</template>
				</el-table-column>
				<el-table-column prop="" label="推广码" width="80" show-overflow-tooltip>
					<template slot-scope="scope">
						<el-button v-if="scope.row.level!=3" size="mini" type="text"
							@click="showBigImg=true;showBigImgUrl=scope.row.tgcode;">查看</el-button>
						<span v-else>/</span>
					</template>
				</el-table-column>
				<el-table-column prop="" label="邀请码" width="80" show-overflow-tooltip>
					<template slot-scope="scope">
						<el-button v-if="scope.row.level!=1" size="mini" type="text"
							@click="showBigImg=true;showBigImgUrl=scope.row.yqcode;">查看</el-button>
						<span v-else>/</span>
					</template>
				</el-table-column>
				<el-table-column prop="" label="操作" width="420">
					<template slot-scope="scope">
						<el-button size="mini" :type="scope.row.level==3?'info':'success'" icon="el-icon-plus" plain
							@click="showAddVisible('add',scope.row)" :disabled="scope.row.level==3">添加下一级</el-button>
						<el-button size="mini" type="primary" icon="el-icon-edit" plain
							@click="showAddVisible('edit',scope.row)" :loading="scope.row.editLoading">编辑</el-button>
						<el-button size="mini" type="danger" plain icon="el-icon-delete" v-if="scope.row.level!=1"
							@click="deleteItem(scope.row)">删除</el-button>
						<el-button size="mini" type="warning" plain icon="el-icon-document"
							v-if="scope.row.level!=1&&scope.row.is_audit!=1"
							@click="showAuditVisible(scope.row)">{{scope.row.is_audit==0?'审核':'重新审核'}}</el-button><!--（0 未审核 1审核通过 2审核不通-->
					</template>
				</el-table-column>
			</el-table>
			<!--新增/修改弹框-->
			<el-dialog :close-on-click-modal="false" :title='titleName' v-if="addVisible" :visible.sync="addVisible"
				width="700px" top="20vh" :append-to-body="true">
				<el-form :inline="true" ref="addForm" :model="addForm" label-width="100px" :rules="addFormRules">
					<el-form-item label="姓名" prop="username">
						<el-input v-model.trim="addForm.username" size="small" placeholder="请输入姓名"></el-input>
					</el-form-item>
					<el-form-item label="性别" prop="sex">
						<el-select v-model.trim="addForm.sex" placeholder="请选择性别" size="small" class="selectWdith">
							<el-option v-for="(item,index) in sexs" :key="index" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="手机号" prop="phone">
						<el-input v-model.trim="addForm.phone" size="small" placeholder="请输入手机号"></el-input>
					</el-form-item>
					<el-form-item label="身份证号" prop="id_card">
						<el-input v-model.trim="addForm.id_card" size="small" placeholder="请输入身份证号"></el-input>
					</el-form-item>
					<el-form-item label="业务上级" prop="parent" v-if="row">
						<el-input v-model.trim="addForm.parent" disabled size="small" placeholder="请输入业务上级"></el-input>
					</el-form-item>
					<el-form-item label="区域代码" prop="range_code">
						<el-input v-model.trim="addForm.range_code" size="small" placeholder="请输入区域代码"></el-input>
					</el-form-item>
					<el-form-item label="区域范围" prop="range">
						<el-input v-model.trim="addForm.range" type="textarea" :rows="5" maxlength="500"
							style="width:510px" size="small" placeholder="请输入区域范围，限制500字符以内"></el-input>
					</el-form-item>

					<el-form-item label="头像" prop="avatar_url"
						:rules="{ required: true, message: '头像不能为空', trigger: 'blur'}"><!---->
						<div style="width:510px">
							<el-upload action="#" :class="{'hideUpload':hideUpload}" :on-change="uploadImgChange"
								list-type="picture-card" :file-list="addForm.fileList" :on-remove="uploadImgRemove"
								:auto-upload="false">
								<i slot="default" class="el-icon-plus"></i>
							</el-upload>
						</div>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button size="small" @click="addVisible=false" v-if="type=='add'">取&nbsp;消</el-button>
					<el-button type="primary" size="small" @click="saveEdit('addForm',type)">保&nbsp;存</el-button>
				</span>
			</el-dialog>

			<!--图片预览弹窗-->
			<el-dialog :close-on-click-modal="false" title='二维码' v-if="showBigImg" :visible.sync="showBigImg"
				width="300px" top="20vh" :append-to-body="true">
				<img :src="'data:image/png;base64,'+showBigImgUrl" width="100%">
			</el-dialog>
			<!--审核弹窗-->
			<el-dialog :close-on-click-modal="false" :title='titleName' v-if="isAuditVisible"
				:visible.sync="isAuditVisible" width="600px" top="20vh" :append-to-body="true">
				<el-form ref="auditForm" :model="auditForm" label-width="100px" :rules="auditFormRules">
					<el-form-item label="是否通过" prop="isAduit">
						<el-radio-group v-model="auditForm.isAduit">
							<el-radio size="small" label="1" border>审核通过</el-radio>
							<el-radio size="small" label="2" border>审核未通过</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button size="small" @click="isAuditVisible=false">取&nbsp;消</el-button>
					<el-button type="primary" size="small" @click="isAudit('auditForm')">保&nbsp;存</el-button>
				</span>
			</el-dialog>
			<!--弹出列表弹框-->
			<el-dialog :close-on-click-modal="false" :title='titleName' v-if="showAlertDialog"
				:visible.sync="showAlertDialog" width="1000px" top="20vh" :append-to-body="true">
				<el-table v-loading="alertLoading" ref="table" height="375px" :data="alertTable" row-key="id" border
					size="mini" style="width: fit-content;">
					<el-table-column type="index" label="序号" width="80" show-overflow-tooltip></el-table-column>
					<el-table-column prop="username" v-if="alertType==1" label="人员姓名" width="140"
						show-overflow-tooltip></el-table-column>
					<el-table-column prop="doctorName" v-if="alertType==2" label="人员姓名" width="140"
						show-overflow-tooltip></el-table-column>
					<el-table-column prop="sex" label="性别" width="100" show-overflow-tooltip></el-table-column>
					<el-table-column prop="phone" label="手机号" width="160" show-overflow-tooltip></el-table-column>
					<el-table-column prop="range" v-if="alertType==1" label="区域范围" width="460"
						show-overflow-tooltip></el-table-column>
					<el-table-column prop="hospital_name" v-if="alertType==2" label="所属机构" width="195"
						show-overflow-tooltip></el-table-column>
					<el-table-column prop="hospital_name" v-if="alertType==2" label="机构地址" width="280"
						show-overflow-tooltip>
						<template slot-scope="scope">
							<span>{{scope.row.province}} {{scope.row.city}} {{scope.row.county}} {{county}}</span>
						</template>
					</el-table-column>


				</el-table>
				<!--TODO 分页-->
				<div class="pagination table-width-b" v-if="alertSearchForm.totalPage!=0" :style="'max-width:100%;'">
					<el-pagination @size-change="handleSizeChange" :page-sizes="[10,20,50]"
						:page-size="alertSearchForm.limit" :current-page.sync="alertSearchForm.currentPage"
						@current-change="handleCurrentChange" layout="jumper, prev, pager, next, sizes, total"
						:total="alertSearchForm.total">
					</el-pagination>
				</div>
			</el-dialog>
		</div>
	</div>
</template>
<script>
	import BaseUrl from '@/utils/baseUrl';
	import regulars from '@/utils/regulars.js';
	export default {
		components: {},

		data() {
			////校验手机号，号段主要有(不包括上网卡)：130~139、150~153，155~159，180~189、170~171、    176~178。14号段为上网卡专属号段
			const telReg = /^(((13[0-9])|(14[0-9])|(15[0-9])|(16[0-9])|(17[0-9])|(18[0-9])|(19[0-9]))\d{8})$/;
			var telValue = regulars.checkRegular(telReg, '请输入手机号码', '手机号码输入不合法!');
			const personIDReg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
			var personIDValue = regulars.checkRegular2(personIDReg, '身份证号码输入不合法');
			//校验手机号重复性
			var checkRepeat = (rule, value, callback) => {
				if (value == '' || value == null) {
					callback(new Error('请输入手机号码'));
				} else {
					this.$http.post(BaseUrl.checkDoctorPhone, {
						"id": this.addForm.id,
						"phone": this.addForm.phone
					}).then(res => {
						if (res.data.code == 0) {
							if (res.data.data == 0) {
								callback();
							} else {
								callback(new Error('当前手机号码重复'));
							}
						} else {
							callback(new Error('手机号码校验失败'));
						}
					});
				}
			};
			return {

				footWidth: '0px',
				loading: true, //列表loading
				tableData: [],
				searchForm: {
					// limit:limit,
					// currentPage:1
				},

				sexs: [{
						name: '男',
						id: '男'
					},
					{
						name: '女',
						id: '女'
					},
				],


				levelOption: [{
						level_name: '一级代理',
						level: '1'
					},
					{
						level_name: '二级代理',
						level: '2'
					},
					{
						level_name: '三级代理',
						level: '3'
					},
				],
				types: [{
						column_type: '视频'
					},
					{
						column_type: '图文'
					},
				],
				models: [{
						model: '轮播图 + 列表'
					},
					{
						model: '列表'
					},
				],

				titleName: '',
				addVisible: false,
				addForm: {},
				addFormRules: {
					username: [{
						required: true,
						message: "请输入姓名",
						trigger: "blur"
					}],
					sex: [{
						required: true,
						message: "请选择性别",
						trigger: "change"
					}],
					phone: [
						// { required: true, message: "请输入手机号", trigger: "blur" }
						{
							required: true,
							trigger: 'blur',
							validator: telValue
						},
					],
					id_card: [{
						required: false,
						trigger: 'blur',
						validator: personIDValue
					}],
					/*range: [
                        { required: true, message: "请输入区域范围", trigger: "blur" }
	                ],
	                avatar_url:[
                        { required: true, message: "请上传头像", trigger: "change" }
                    ],*/
				},
				auditForm: {},
				auditFormRules: {
					isAduit: [{
						required: true,
						message: "请选择审核结果",
						trigger: "change"
					}],
				},

				type: '', //add,edit
				row: null,
				hideUpload: false,
				showBigImg: false,
				showBigImgUrl: '',
				isAuditVisible: false, //审核弹窗
				alertSearchForm: {
					limit: 10,
					currentPage: 1,
					total: 0,
					totalPage: 0,
				},
				alertLoading: false,
				alertTable: [],
				showAlertDialog: false,
				alertType: null,
			}
		},
		created() {
			this.getData()
		},
		mounted() {
			var that = this;

			that.footWidth = that.$refs.table.bodyWidth;
		},
		methods: {
			//列表数据
			getData() {
				this.loading = true;
				this.$http.post(BaseUrl.getSalesmanPageList, this.searchForm).then(res => { //,this.searchForm
					if (res.data.code == 0) {
						this.loading = false;
						if (res.data.data) {
							this.tableData = this.handleTree(res.data.data, "id", null, null, 0);
						}

					} else {
						this.$message({
							message: res.data.msg,
							type: 'warning'
						})
					}
				})

			},

			//searchForm重置
			reset() {
				this.resetSearchForm(this.searchForm);
				// this.getData();
			},
			//显示新增/编辑弹框
			showAddVisible(type, row) {
				console.log(arguments)
				let that = this;
				this.hideUpload = false;
				this.addForm = {
					avatar_url: '',
					fileList: []
				};
				this.type = type;
				this.row = row;

				if (type == 'add') {
					if (row) {

						this.addForm.parent_phone = row.phone;
						this.addForm.parent_name = row.username;
						this.addForm.parent = this.addForm.parent_name + '（' + this.addForm.parent_phone + '）';
					}
					this.addForm.parent_id = row ? row.id : 0;
					let ancestorsArr = row ? [row.ancestors, this.addForm.parent_id] : [this.addForm.parent_id];
					this.addForm.ancestors = ancestorsArr.toString();
					this.titleName = "新增人员";
					this.hideUpload = false;
					this.addVisible = true;


				} else {
					this.titleName = "修改人员";
					this.$set(row, 'editLoading', true);
					this.$http.post(BaseUrl.getSalesmanById, {
						id: row.id
					}).then(res => {
						if (res.data.code == 0) {
							that.addForm = res.data.data;
							console.log(this.addForm.parent_name);
							if (!this.addForm.parent_name) {
								this.addForm.parent = '无'
							} else {
								this.addForm.parent = this.addForm.parent_name + '（' + this.addForm.parent_phone +
									'）'
							}

							that.addForm.fileList = [];
							if (that.addForm.avatar_url) {
								var name = that.addForm.avatar_url.split("witmed/")[1];
								that.addForm.fileList.push({
									name: name,
									url: that.addForm.avatar_url + '?v=' + new Date().getTime()
								})
								that.hideUpload = true;
							}

							that.addVisible = true;
							that.$set(row, 'editLoading', false);
						} else {
							this.$message({
								message: res.data.msg,
								type: 'warning'
							})
						}
					})

				}

			},

			//保存新增/修改
			saveEdit(form, type) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						if (type == 'add') {
							this[form].level = this.row ? (+this.row.level) + 1 : 1;
							this[form].is_audit = 0;
							this.$http.post(BaseUrl.addSalesmanInfo, this[form]).then(res => {
								if (res.data.code == 0) {
									this.titleName = '';
									this.getData();
									this.addVisible = false;
									this.hideUpload = false;
									this.$message({
										message: res.data.msg,
										type: 'success'
									})
								} else {
									this.$message({
										message: res.data.msg,
										type: 'warning'
									})
								}
							})
						} else {
							this.addForm.id = this.row.id;
							let form = JSON.parse(JSON.stringify(this.addForm));
							delete form.create_time
							delete form.score
							delete form.sup_phone
							delete form.checkCode
							delete form.children
							delete form.promCount
							delete form.doctorCount
							delete form.tgcode
							delete form.yqcode
							this.$http.post(BaseUrl.updSalesmanById, form).then(res => {
								if (res.data.code == 0) {
									this.titleName = '';
									this.getData();
									this.addVisible = false;
									this.hideUpload = false;
									this.$message({
										message: res.data.msg,
										type: 'success'
									})
								} else {
									this.$message({
										message: res.data.msg,
										type: 'warning'
									})
								}
							})
						}
					}
				})

			},
			//删除
			deleteItem(row) {
				var that = this;
				this.$confirm('是否确认删除"' + row.username + '"的数据项?', "警告", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(function() {
					that.$http.post(BaseUrl.delSalesman, {
						id: row.id
					}).then(res => {
						if (res.data.code == 0) {
							that.getData();
							that.$message({
								message: '删除成功',
								type: 'success'
							})
						} else {
							that.$message({
								message: res.data.msg,
								type: 'warning'
							})
						}

					})

				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			//审核
			showAuditVisible(row) {
				this.auditForm = {
					id: row.id,
					isAduit: '',
				};
				this.titleName = '审核';
				this.isAuditVisible = true;
			},
			//审核方法
			isAudit(form) {
				this.$refs.auditForm.validate((valid) => {
					if (valid) {
						this.$http.post(BaseUrl.updAudit, this.auditForm).then(res => {
							if (res.data.code == 0) {
								this.titleName = '';
								this.getData();
								this.isAuditVisible = false;
								this.$message({
									message: res.data.msg,
									type: 'success'
								})
							} else {
								this.$message({
									message: res.data.msg,
									type: 'warning'
								})
							}
						})
					}
				})
			},
			//关闭弹框
			noClick() {
				this.getData();
				this.hideUpload = false;
				this.addVisible = false;
			},
			//上传图片
			uploadImgChange(file, fileList) {
				let that = this;
				console.log(file);
				console.log(fileList)
				const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
				if (!isJPG) {
					this.$message.error('上传图片只能是JPG或PNG 格式!');
					fileList.splice(fileList.length - 1, 1)
					return false;
				}
				let fileName = file.name.lastIndexOf("."); //取到文件名开始到最后一个点的长度
				let fileNameLength = file.name.length; //取到文件名长度
				let fileFormat = file.name.substring(fileName + 1, fileNameLength); //截
				file.name = new Date().getTime() + '.' + fileFormat;
				var newfile = new File([file.raw], file.name, {
					type: file.raw.type
				});
				// var name =file.name;
				// console.log(this.addForm.fileList);
				// this.addForm.fileList.push({name:name});
				this.hideUpload = fileList.length >= 1;
				this.saveImg(newfile, 'img');
			},
			//上传图片
			saveImg(file, type) {
				console.log(type);
				let that = this;
				var fd = new FormData();
				var f = file;
				fd.append('file', f);
				// this.addForm.fileList=[];
				this.$axios.post(BaseUrl.uploadAvatarUrl, fd, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(res => {
					if (res.data.code == 0) {
						that.addForm.avatar_url = res.data.data;
						this.$refs['addForm'].clearValidate(['avatar_url']);
					} else {
						return false;
					}
				});
			},
			//删除图片
			uploadImgRemove(file) {
				let that = this;
				console.log(file);
				this.$http.post(BaseUrl.delAvatarUrl, {
					fileName: file.name
				}).then(res => {
					if (res.data.code == 0) {
						that.addForm.fileList = []
						that.hideUpload = false;
						that.addForm.avatar_url = '';
					} else {
						this.$message({
							message: res.data.msg,
							type: 'warning'
						})
					}
				})
			},
			getAlertData(row, type) {
				this.alertTable = [];
				this.alertType = type;
				this.alertSearchForm.currentPage = 1;
				this.alertSearchForm.id = row.id;
				this.showAlertDialog = true;
				if (type == 1) { //推广
					this.titleName = '推广明细'
					this.getSalesmanListByAncestors()
				} else {
					this.titleName = '邀请明细'
					this.getDoctorListBySaleId();
				}
			},
			//每页展示条数变化
			handleSizeChange(val) {
				this.alertSearchForm.limit = val;
				if (this.alertType == 1) { //推广
					this.getSalesmanListByAncestors()
				} else {
					this.getDoctorListBySaleId();
				}
			},
			//翻页
			handleCurrentChange(val) {
				this.alertSearchForm.currentPage = val;
				if (this.alertType == 1) { //推广
					this.getSalesmanListByAncestors()
				} else {
					this.getDoctorListBySaleId();
				}
			},
			//推广明细
			getSalesmanListByAncestors() {

				this.alertLoading = true;
				this.$http.post(BaseUrl.getSalesmanListByAncestors, this.alertSearchForm).then(res => { //,this.searchForm
					if (res.data.code == 0) {
						this.alertLoading = false;
						if (res.data.data) {
							this.alertTable = res.data.data.data;
							this.alertSearchForm.total = res.data.data.total;
							this.alertSearchForm.totalPage = res.data.data.totalPage;
						}

					} else {
						this.$message({
							message: res.data.msg,
							type: 'warning'
						})
					}
				})
			},
			//邀请明细
			getDoctorListBySaleId() {
				this.alertLoading = true;
				this.$http.post(BaseUrl.getDoctorListBySaleId, this.alertSearchForm).then(res => { //,this.searchForm
					if (res.data.code == 0) {
						this.alertLoading = false;
						if (res.data.data) {
							this.alertTable = res.data.data.data;
							this.alertSearchForm.total = res.data.data.total;
							this.alertSearchForm.totalPage = res.data.data.totalPage;
						}
					} else {
						this.$message({
							message: res.data.msg,
							type: 'warning'
						})
					}
				})
			},
			/**
			 * 导出
			 */
			exportFile() {
				let obj = this.searchForm;
				this.$http.postFile(BaseUrl.busInfoExport, obj, {
					responseType: 'arraybuffer'
				}).then(res => {
					var blob = new Blob([res.data], {
						type: 'application/vnd.ms-excel;charset=utf-8'
					});
					var downloadElement = document.createElement('a');
					var objectUrl = URL.createObjectURL(blob);
					downloadElement.href = objectUrl;
					downloadElement.download = '业务员人员资料报表.xls'; //下载后文件名
					document.body.appendChild(downloadElement);
					downloadElement.click(); //点击下载
					document.body.removeChild(downloadElement); //下载完成移除元素
					window.URL.revokeObjectURL(objectUrl); //释放掉blob对象
				}).catch(
					function(res) {}
				)

			},

		}

	}
</script>
<style>
	/* .columnPage .el-table__row.expanded .el-table_1_column_1  {
    font-weight: bold;
    color: #333;;
} */
	.hideUpload .el-upload--picture-card {
		display: none;
	}
</style>
<style scoped>
	.selectWdith {
		width: 200px;
	}
</style>