<template>
    <div class="table">
        <div class="container"  >
        	<el-form :inline="true" :model="searchForm" ref="searchForm">
            	<el-form-item  label="一级栏目">
                    <el-autocomplete
                        @focus="inputFormFocus('friColuSelect')"
                        class="inline-input"
                        size="small" 
                        v-model="searchForm.frist_column_name"
                        :fetch-suggestions="querySearch"
                        placeholder="请输入一级栏目"
                        @select="handleSelect"
                    ></el-autocomplete>
                </el-form-item>
                <el-form-item prop="deptId" label="二级栏目">
                        <el-autocomplete
                            @focus="inputFormFocus('secColuSelect')"
                            class="inline-input"
                            size="small" 
                            v-model="searchForm.sec_column_name"
                            :fetch-suggestions="querySearch"
                            placeholder="请输入二级栏目"
                            @select="handleSelect"
                        ></el-autocomplete>
			    </el-form-item>
                
                <el-form-item label="内容类型" prop="level">
					<el-select v-model.trim="searchForm.content_type" placeholder="请选择内容类型" size="small" style= "width: 190px;">
                        <el-option label='全部' value=''></el-option>
						<el-option
							v-for="(item,index) in types"
							:key="index"
							:label="item.content_type"
							:value="item.content_type">
						</el-option>
					</el-select>
			    </el-form-item>
			   
                <el-form-item>
                    <el-button type="primary" plain icon="el-icon-search" size="small" @click="getData()">查询</el-button>
                    <el-button type="warning" plain icon="el-icon-refresh" size="small" @click="reset()">重置</el-button>
                    <el-button type="success" plain size="small" icon="el-icon-plus" @click="showAddVisible('add')" >新增</el-button>
                </el-form-item>
            </el-form>
            <el-table v-loading="loading" ref="table" :height="(tableH+40)+'px'" :data="tableData" row-key="id" border  default-expand-all size="mini" :tree-props="{children: 'children', hasChildren: 'hasChildren'}"  >
                <!--<el-table-column prop="frist_column_name" label="一级栏目" width="240" show-overflow-tooltip></el-table-column>
                <el-table-column prop="sec_column_name" label="二级栏目" width="240" show-overflow-tooltip></el-table-column>-->
                <el-table-column prop="frist_column_name" label="栏目名称" width="240" show-overflow-tooltip></el-table-column>
                <el-table-column prop="content_type" label="内容类型" width="150" show-overflow-tooltip></el-table-column>
                <el-table-column prop="topic_name" label="题目名称" width="*" show-overflow-tooltip></el-table-column>
                <el-table-column prop="state" label="状态" width="150" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span :style="{color:scope.row.state==0?'red':''}">{{scope.row.state==0?'未上架':'已上架'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="" label="操作" width="380">
                    <template slot-scope="scope">
                    	<el-button size="mini" type="success" icon="el-icon-plus"  :plain="scope.row.state!=0" @click="updContentState(scope.row)">{{scope.row.state==0?'上架':'下架'}}</el-button>
                        <el-button size="mini" type="warning" icon="el-icon-edit"  :plain="scope.row.slides_show!=0" @click="updSlides(scope.row)" >{{scope.row.slides_show==0?'启用轮播图':'禁用轮播图'}}</el-button>
                        <el-button size="mini" type="primary" icon="el-icon-edit"  plain @click="showAddVisible('edit',scope.row)" :loading="scope.row.editLoading" >编辑</el-button>
						<el-button size="mini" type="danger" plain icon="el-icon-delete" @click="deleteItem(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--TODO 分页-->
            <div class="pagination table-width-b" v-if="totalPage!=0" :style="'width:'+footWidth+';max-width:100%;'">
                <el-pagination 
                    @size-change="handleSizeChange" 
                    :page-sizes="[10,20,50]"
                    :page-size="searchForm.limit"
                    :current-page.sync="searchForm.currentPage"
                    @current-change="handleCurrentChange"
                    layout="jumper, prev, pager, next, sizes, total"
                    :total="total">
                </el-pagination>
            </div>
            <!--新增/修改弹框--><!---->
            <el-dialog :close-on-click-modal="false" :title='titleName' v-if="addVisible" :visible.sync="addVisible" width="850px" top="20vh" :append-to-body="true" :before-close="noClick">
			    <el-form :inline="true" ref="addForm" :model="addForm" label-width="100px" :rules="addFormRules">
                    <el-form-item prop="frist_column_id" label="一级栏目">
                        <el-autocomplete
                            style="width:280px"
                            @input="fristColumnInp"
                            @focus="inputFormFocus('friColuSelectAddForm')"
                            class="inline-input"
                            size="small" 
                            v-model="addForm.frist_column_name"
                            :fetch-suggestions="querySearch"
                            placeholder="请输入一级栏目"
                            @select="handleSelect"
                        ></el-autocomplete>
			        </el-form-item>
                    <el-form-item prop="sec_column_id" label="二级栏目">
                        <el-autocomplete
                            style="width:280px"
                            @input="secColumnInp"
                            @focus="inputFormFocus('secColuSelectAddForm')"
                            class="inline-input"
                            size="small" 
                            v-model="addForm.sec_column_name"
                            :fetch-suggestions="querySearch"
                            placeholder="请输入二级栏目"
                            @select="handleSelect"
                        ></el-autocomplete>
			        </el-form-item>
                    <el-form-item prop="content_type" label="内容类型">
                        <el-input v-model.trim="addForm.content_type" disabled style="width:280px" size="small" placeholder="请输入题目名称"></el-input>
			        </el-form-item>
                    <el-form-item  label="题目名称" prop="topic_name">
                        <el-input v-model.trim="addForm.topic_name" style="width:280px" size="small" placeholder="请输入题目名称"></el-input>
                    </el-form-item>
                    <el-form-item  label="图片上传" prop="img_url">
                        <el-upload action="#" :class="{'hideUpload':hideUpload}" :before-remove="bfRemove" :on-change="uploadImgChange" list-type="picture-card" :file-list="addForm.fileList" :on-remove="uploadImgRemove" :auto-upload="false">
                            <i slot="default" class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item  label="内容简介" prop="content_syno">
                        <el-input v-model.trim="addForm.content_syno" style="width:670px" size="small" type="textarea" :rows="2" placeholder="请输入内容简介"></el-input>
                    </el-form-item>
                    <el-form-item  label="视频上传" prop="video_url" v-if="addForm.content_type=='视频'">
                        <div class="videoParent">
                            <div class="el-icon-error close" @click="delVideo({name:addForm.video_name})"></div>
                            
                            <video width="320" height="240" v-if="addForm.video_url  && videoFlag == false" :src="addForm.video_url" class="avatar" controls="controls">您的浏览器不支持视频播放</video>
                        </div>
                        
                        <el-upload :class="{'hideUpload':addForm.video_url}" action="#" :on-change="uploadVideoChange" :show-file-list="false" list-type="picture-card" :auto-upload="false">
                            <i v-if="!addForm.video_url && videoFlag == false" class="el-icon-plus avatar-uploader-icon"></i>
                            <el-progress v-if="videoFlag == true" type="circle" :width="100" :percentage="videoUploadPercent" style="margin-top:30px;z-index:99;"></el-progress><!---->
                        </el-upload>
                        <p class="text" v-if="!addForm.video_url && videoFlag == false">请保证视频格式正确，且不超过20M</p>
                    </el-form-item>
                    <el-form-item  label="内容编辑" prop="content" v-if="addForm.content_type=='图文'">
                        <div style="width:670px"><editor :htmlStr="addForm.content" v-model="addForm.content"></editor></div>
                        
                    </el-form-item>
                </el-form>
			    <span slot="footer" class="dialog-footer">
                    <el-button size="small" @click="noClick()">取&nbsp;消</el-button>
			        <el-button type="primary" size="small" @click="saveEdit('addForm',type)">保&nbsp;存</el-button>
			    </span>
			</el-dialog>
            
			
        </div>
    </div>
</template>
<script>
import BaseUrl from '@/utils/baseUrl';
import regulars from '@/utils/regulars.js';
import editor from '@/components/editor'
    export default {
        components: {
            editor
        },
        
        data() {
            //校验
            var checkRepeat = (rule, value, callback) => {
	        	if(!this.hasAddFormFstId){
	        		callback(new Error('一级栏目不正确'));
	        	}else{
                    callback(); 
                }
	        };
            var checkRepeatSec= (rule, value, callback) => {
	        	if(!this.hasAddFormSecId){
	        		callback(new Error('二级栏目不正确'));
	        	}else{
                    callback(); 
                }
	        };
            return {
                
                footWidth:'0px',
                loading:true,//列表loading
                tableData:[],
                searchForm:{
                    limit:limit,
                    currentPage:1
                },
				levels:[
	            	{column_level:'一级',column_val:'0'},
	            	{column_level:'二级',column_val:'1'},
				],
                types:[
                    {content_type:'视频'},
                    {content_type:'图文'},
                ],
                models:[
                    {model:'轮播图 + 列表'},
                    {model:'列表'},
                ],
                total:0,
                totalPage:0,
                titleName:'',
                addVisible:false,
                addForm:{},
                addFormRules:{
                    frist_column_id:[
                        // { required: true, message: "一级栏目不正确", trigger: "change" },
                        { required: true,  trigger: 'change', validator:checkRepeat },
                    ],
                    sec_column_id: [
	                    // { required: true, message: "二级栏目不正确", trigger: "change" }
                        { required: true,  trigger: 'change', validator:checkRepeatSec },
	                ],
                    content_type:[
	                    { required: true, message: "请选择内容类型", trigger: "change" }
	                ],
	                topic_name: [
                        { required: true, message: "请输入题目名称", trigger: "blur" }
	                ],
	                img_url:[
                        { required: true, message: "请上传图片", trigger: "blur" }
                    ],
                    content_syno:[
                        { required: true, message: "请输入内容简介", trigger: "blur" }
                    ],
                    video_url:[
                        { required: true, message: "请上传视频", trigger: "blur" }
                    ],
                    content:[
                        { required: true, message: "请输入内容编辑", trigger: "blur" }
                    ],
                },
                type:'',//add,edit
                row:null,
                videoFlag:false,//视频上传进度
                videoUploadPercent:0,//视频上传进度条
                friColuSelect:[],//一级栏目下拉
                secColuSelect:[],//二级栏目下拉
                friColuSelectAddForm:[],//一级栏目下拉
                secColuSelectAddForm:[],//二级栏目下拉
                inputFormActive:'',
                hideUpload:false,//隐藏图片上传
                hasAddFormFstId:false,
                hasAddFormSecId:false,

            }
        },
        created(){
            this.getData()
            this.getFriColuSelect().then(res=>{
                this.friColuSelect=res;
                this.friColuSelectAddForm=res;
            })
        },
        mounted(){
            var that=this;
            
            that.footWidth=that.$refs.table.bodyWidth;
            
            
        },
        methods:{
            
            getData(){
                this.$http.post(BaseUrl.getContentPageList,this.searchForm).then(res=>{
					if(res.data.code==0){
                        this.loading = false;
                        console.log(typeof res.data.data.data);
                        this.totalPage = res.data.data.totalPage;
                        this.total = res.data.data.total;
                        this.loading=false;//列表loading
                        if(res.data.data.data){
                            this.tableData = this.handleTree(res.data.data.data, "id");
                        }
                        
                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        })
                    }
                })
                
            },
            handleSizeChange(val){
                this.searchForm.limit = val;
                this.getData();
            },
            handleCurrentChange(val){
                this.searchForm.currentPage = val;
                this.getData();
            },
            reset(){
                this.resetSearchForm(this.searchForm,[{k:'limit',v:this.searchForm.limit},{k:'currentPage',v:this.searchForm.currentPage}]);
                this.secColuSelect=[]//二级栏目下拉
                // this.getData();
            },
            //上架/下架
            updContentState(row){
                let state=row.state==1?'0':1;
                let stateName=row.state==0?'上架':'下架';
                this.$confirm('是否确认' +stateName+'"'+row.topic_name + '"的数据项?', "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(()=>{
                    this.$http.post(BaseUrl.updContentState,{state:state,id:row.id}).then(res=>{
                        if(res.data.code==0){
                            this.getData();
                            this.$message({
                                message: res.data.msg,
                                type: 'success'
                            })
                        }else{
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            })
                        }
                    })
                }).catch(()=>{

                })
            },
            //轮播图
            updSlides(row){
                let slidesShow=row.slides_show==1?'0':1;
                this.$confirm('是否确认修改'+row.topic_name +'的轮播数据项?', "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(()=>{
                    this.$http.post(BaseUrl.updSlides,{state:slidesShow,id:row.id}).then(res=>{
                        if(res.data.code==0){
                            this.getData();
                            this.$message({
                                message: res.data.msg,
                                type: 'success'
                            })
                        }else{
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            })
                        }
                    })
                }).catch(()=>{

                })
            },
            //一级栏目下拉
            getFriColuSelect(column_name){
                return new Promise((resolve, reject)=> {
                     this.$http.post(BaseUrl.getFriColuSelect,{column_name:column_name}).then(res=>{
                        if(res.data.code==0){
                            let friColuSelect=res.data.data.map(item=>{
                                item.value=item.column_name;
                                return item;
                            });
                            resolve(friColuSelect);
                        }else{
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            })
                        }
                    })
                })
            },
            //二级栏目下拉
            getSecColuSelect(column_name,id){
                return new Promise((resolve, reject)=> {
                    this.$http.post(BaseUrl.getSecColuSelect,{column_name:column_name,id:id}).then(res=>{
                        if(res.data.code==0){
                            let secColuSelect=res.data.data.map(item=>{
                                item.value=item.column_name;
                                return item;
                            });
                            resolve(secColuSelect);
                        }else{
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            })
                        }
                    })
                })
            },
            inputFormFocus(val){
                this.inputFormActive=val;
            },
            querySearch(queryString, cb) {
                var restaurants = this[this.inputFormActive];
                var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
                // 调用 callback 返回建议列表的数据
                cb(results);
            },
            createFilter(queryString) {
                return (restaurant) => {
                return (restaurant.value.indexOf(queryString) >= 0);
                };
            },
            handleSelect(item) {
                console.log(item);
                console.log(this.inputFormActive);
                if(this.inputFormActive=='friColuSelect'){
                    if(item.id!=this.addForm.frist_column_id){
                        delete this.searchForm.sec_column_id;
                        delete this.searchForm.sec_column_name;
                    }
                    this.searchForm.frist_column_id=item.id;
                    this.getSecColuSelect('',item.id).then(res=>{
                        this.secColuSelect=res;
                    })
                }else if(this.inputFormActive=='secColuSelect'){
                    this.searchForm.sec_column_id=item.id;
                }else if(this.inputFormActive=='friColuSelectAddForm'){
                    if(item.id!=this.addForm.frist_column_id){
                        delete this.addForm.sec_column_id;
                        delete this.addForm.sec_column_name;
                    }
                    
                    this.addForm.frist_column_id=item.id;
                    this.addForm.frist_column_name=item.column_name;
                    this.getSecColuSelect('',item.id).then(res=>{
                        this.secColuSelectAddForm=res;
                    })
                    this.hasAddFormFstId=true;
                }else if(this.inputFormActive=='secColuSelectAddForm'){
                    this.addForm.sec_column_id=item.id;
                    this.addForm.sec_column_name=item.column_name;
                    this.hasAddFormSecId=true;
                    this.addForm.content_type=item.column_type;
                }
            },
            fristColumnInp(){
                this.addForm.frist_column_id='';
                this.hasAddFormFstId=false;
            },
            secColumnInp(){
                this.addForm.sec_column_id='';
                this.hasAddFormSecId=false;
            },
            showAddVisible(type,row){
                let that=this;
                this.addForm={
                    video_url:'',
                    img_url:'',
                };
                this.hideUpload=false;
                this.type=type;
                this.row=row;
                this.addForm.column_level='0';
                this.addForm.fileList=[];
                this.videoUploadPercent=0;//上传视频进度条0
                this.videoFlag=false//上传视频进度条

                if(type=='add'){
                    if(row){
                        this.addForm.column_level='1'
                    }
                    this.addForm.parent_id=row?row.id:0;
                    let ancestorsArr=row?[row.ancestors,this.addForm.parent_id]:[this.addForm.parent_id];
                    this.addForm.ancestors=ancestorsArr.toString();
                    this.titleName="新增内容";
                    this.addVisible=true;
                    
                }else{
                    this.titleName="修改内容";
                    this.$set(row,'editLoading',true);
                    this.$http.post(BaseUrl.getContentById,{id:row.id}).then(res=>{
                        if(res.data.code==0){
                            this.addForm=res.data.data;
                            this.hasAddFormFstId=true;
                            this.hasAddFormSecId=true;
                            this.addVisible=true;
                            
                            this.$set(row,'editLoading',false);
                            this.addForm.fileList=[];
                            this.getSecColuSelect('',this.addForm.frist_column_id).then(res=>{
                                this.secColuSelectAddForm=res;
                            })
                            var name =that.addForm.img_url.split("zjxy/")[1];
                            this.addForm.fileList.push({name:name,url:res.data.data.img_url+'?v='+new Date().getTime()});
                            this.addForm.video_name=that.addForm.video_url.split("zjxy/")[1];
                            this.addForm.video_url=res.data.data.video_url+'?v='+new Date().getTime();
                            this.hideUpload=true;
                        }else{
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            })
                        }
                    })
                    
                }
                
            },
            
            //保存新增/修改
            saveEdit(form,type){
                console.log(this.addForm)
                this.$refs[form].validate((valid)=>{
                    if(valid){
                        if(type=='add'){
                            this.addForm.slides_show=0;
                            this.addForm.state=0;
                            this.$http.post(BaseUrl.addContentInfo,this[form]).then(res=>{
                                if(res.data.code==0){
                                    this.titleName='';
                                    this.getData();
                                    this.addVisible=false;
                                    this.$message({
                                        message: res.data.msg,
                                        type: 'success'
                                    })
                                }else{
                                    this.$message({
                                        message: res.data.msg,
                                        type: 'warning'
                                    })
                                }
                            })
                        }else{
                            this.addForm.id=this.row.id;
                            let form=JSON.parse(JSON.stringify(this.addForm));
                            form.video_url=form.video_url.split("?v=")[0];
                            this.$http.post(BaseUrl.updContentById,form).then(res=>{
                                if(res.data.code==0){
                                    this.titleName='';
                                    this.getData();
                                    this.addVisible=false;
                                    this.$message({
                                        message: res.data.msg,
                                        type: 'success'
                                    })
                                }else{
                                    this.$message({
                                        message: res.data.msg,
                                        type: 'warning'
                                    })
                                }
                            })
                        }
                    }
                })

            },
            deleteItem(row){
                var that=this;
                this.$confirm('是否确认删除内容"' + row.topic_name + '"的数据项?', "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(function() {
                    that.$http.post(BaseUrl.delContent,{id:row.id}).then(res=>{
                        if(res.data.code==0){
                            that.getData();
                            that.$message({
                                message:'删除成功',
                                type:'success'
                            })
                        }else{
                            that.$message({
                                message: res.data.msg,
                                type: 'warning'
                            })
                        }
                        
                    })
                    
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
                });
            },
            noClick(){
                this.getData();
                this.secColuSelectAddForm=[]//二级栏目下拉
                this.addVisible=false;
                this.addForm={};
            },
            //上传图片
            uploadImgChange(file,fileList){
                let that=this;
                console.log(file);
                console.log(fileList)
                const isJPG = file.raw.type === 'image/jpeg'||file.raw.type === 'image/png';
                if (!isJPG) {
                    this.$message.error('上传图片只能是JPG或PNG 格式!');
                    fileList.splice(fileList.length-1,1)
                    return false;
                }
                let fileName = file.name.lastIndexOf(".");//取到文件名开始到最后一个点的长度
                let fileNameLength = file.name.length;//取到文件名长度
                let fileFormat = file.name.substring(fileName + 1, fileNameLength);//截
                file.name=new Date().getTime()+'.'+fileFormat;
                var newfile = new File([file.raw], file.name,{type:file.raw.type});
                // var name =file.name;
                // console.log(this.addForm.fileList);
                // this.addForm.fileList.push({name:name});
                this.hideUpload = fileList.length >= 1;
                this.saveImg(newfile,'img');
            },
             //上传视频
            uploadVideoChange(file){
                console.log(file);
                const isLt20M = file.size / 1024 / 1024  < 20;
                if (['video/mp4', 'video/ogg', 'video/flv','video/avi','video/wmv','video/rmvb'].indexOf(file.raw.type) == -1) {
                    this.$message.error('请上传正确的视频格式');
                    return false;
                }
                if (!isLt20M) {
                    this.$message.error('上传视频大小不能超过20MB!');
                    return false;
                }
                let fileName = file.name.lastIndexOf(".");//取到文件名开始到最后一个点的长度
                let fileNameLength = file.name.length;//取到文件名长度
                let fileFormat = file.name.substring(fileName + 1, fileNameLength);//截
                file.name=new Date().getTime()+'.'+fileFormat;
                var newfile = new File([file.raw], file.name,{type:file.raw.type});

                this.saveImg(newfile,'video');
            },
            //上传图片/视频
            saveImg(file,type){
                console.log(type);
                let that=this;
                var fd = new FormData();
                var f=file;
                fd.append('file', f);
                if(type=='video'){
                    this.videoFlag = true;
                    let timer = setInterval(()=>{
                        let prog=that.videoUploadPercent
                        if(that.videoFlag){
                            if(prog<80){
                                prog=prog+Math.round(Math.random()*5)
                                this.videoUploadPercent = prog;

                            }
                            else{
                                clearInterval(timer);
                            }
                        }else{
                            clearInterval(timer);
                        }
                    },200);
                }
                

                this.$axios.post(BaseUrl.uploadImg, fd, {headers: {'Content-Type': 'multipart/form-data'}}).then(res => {
                        if(res.data.code==0){
                            if(type=='video'){
                                that.videoUploadPercent=100;
                                
                                setTimeout(()=>{
                                    that.videoFlag = false;
                                    that.addForm.video_url=res.data.data;
                                    that.$refs['addForm'].clearValidate(['video_url']);
                                    that.addForm.video_name=that.addForm.video_url.split("zjxy/")[1];
                                },500)
                               
                            }else{
                                this.addForm.img_url=res.data.data;
                                this.$refs['addForm'].clearValidate(['img_url']);
                            }
                        }else{
                            return false;
                        }
                    }
                );
            },
            bfRemove(){
                return new Promise((resolve, reject)=> {
                    if(this.type=='edit'){
                        
                        this.$confirm('删除操作不可撤销，确认删除?', "警告", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "warning"
                        }).then(()=> {
                            resolve();
                        }).catch(()=>{
                            reject();
                        })
                    }else{
                        resolve();
                    }
                })
            },
            //删除图片
            uploadImgRemove(file){
                let that=this;
                this.delImg(file).then(()=>{
                    that.addForm.fileList=[]
                    that.hideUpload=false;
                    that.addForm.img_url='';
                })
                    
            },
            //删除视频
            delVideo(file){
                let that=this;
                if(this.type=='edit'){   
                    this.$confirm('删除操作不可撤销，确认删除?', "警告", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }).then(()=> {
                        this.delImg(file).then(()=>{
                            that.addForm.video_url='';
                            that.addForm.video_name='';
                            that.videoUploadPercent=0;//上传视频进度条0
                            that.videoFlag=false//上传视频进度条
                        })
                    }).catch(()=>{
                    })
                }else{
                    this.delImg(file).then(()=>{
                        that.addForm.video_url='';
                        that.addForm.video_name='';
                    })
                }    
                
            },
            //删除图片/视频
            delImg(file){
                return new Promise((resolve, reject)=> {
                    this.$http.post(BaseUrl.delImg,{fileName:file.name}).then(res=>{
                        if(res.data.code==0){
                            resolve();
                        }else{
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            })
                        }
                    })
                })
            }
        }
        
    }
</script>
<style>
.hideUploadVideo .el-upload--picture-card{
    display:none;

}
</style>
<style scoped lang="scss">
.videoParent{
    position: relative;
    background-color: #ccc;
    .close{
        position: absolute;
        right: 10px;
        top:10px;
        cursor: pointer;
        font-size: 30px;
        color:#fff;
        z-index: 9;
    }
}
.selectWdith {
    width : 200px;
}
.parent{
    font-size: 12px;
    font-weight: bold;
    color: #333;
}
</style>