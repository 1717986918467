<template>
    <div class="table">
        <div class="container"  >
            
        	<el-form :inline="true" :model="searchForm" ref="searchForm">
                <el-form-item prop="searchType" label="">
                    <el-radio-group v-model="searchForm.searchType" @change="changeSearchType()" size="small">
                        <el-radio-button label="1">业务员</el-radio-button>
                        <el-radio-button label="2">医生</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item prop="deptId" label="业务级别" v-if="searchForm.searchType==1" >
                    <el-select v-model="searchForm.level" size="small" filterable clearable placeholder="请选择" style= "width: 190px;">
                        <el-option label="全部" value=''></el-option>
                        <el-option
                        v-for="(item,index) in levelOption"
                        :key="index"
                        :label="item.level_name"
                        :value="item.level">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="药品剂型">
					<el-select v-model="searchForm.drug_type" size="small" clearable placeholder="请选择药品剂型">
						<el-option v-for="item in drugTypeOptions" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
                <el-form-item v-if="searchForm.searchType==1">
                    <el-button type="primary" plain icon="el-icon-search" size="small" @click="getData()">查询</el-button>
                    <el-button type="warning" plain icon="el-icon-refresh" size="small" @click="reset()">重置</el-button>
					<!-- <el-button type="success" plain size="small" icon="el-icon-plus" @click="showAddVisible('add')" >新增</el-button> -->
                </el-form-item>
            </el-form>
            <!--业务员-->
            <el-table v-loading="loading"  v-if="searchForm.searchType==1" key="tableData1" ref="table" highlight-current-row   :data="tableData1" border stripe size="mini" style="width: fit-content;">
                    <el-table-column prop="level_name" label="业务级别" width="200" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="type" label="佣金类型" width="140" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span>{{scope.row.type==1?'直销':'间销'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="rate_value" label="佣金(%)" width="100" show-overflow-tooltip></el-table-column>
                    <!-- <el-table-column prop="dept_name" label="销售额区间" width="130" show-overflow-tooltip></el-table-column> -->
                    <el-table-column prop="drug_type" label="药品剂型" width="300" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="remark" label="备注" width="300" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="" label="操作" align="center" width="100">
                        <template slot-scope="scope">
                            <el-button size="mini" type="primary" icon="el-icon-edit"  plain @click="showAddVisible('edit',scope.row)" :loading="scope.row.editLoading" >编辑</el-button>
                            <!-- <el-button size="mini" type="danger" plain icon="el-icon-delete" @click="deleteStation(scope.row)">删除</el-button> -->
                        </template>
                    </el-table-column>
            </el-table>
            <!--医生-->
            <el-table v-loading="loading"  v-if="searchForm.searchType==2" key="tableData2" ref="table" highlight-current-row   :data="tableData2" border stripe size="mini" style="width: fit-content;">
                <el-table-column prop="rate" label="比例" width="200" show-overflow-tooltip></el-table-column>
                <el-table-column prop="remark" label="备注" width="300" show-overflow-tooltip></el-table-column>
                <el-table-column prop="" label="操作" align="center"  width="100">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" icon="el-icon-edit"  plain @click="showSetVisible(scope.row)" :loading="scope.row.editLoading" >修改</el-button>
                        <!-- <el-button size="mini" type="danger" plain icon="el-icon-delete" @click="deleteStation(scope.row)">删除</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
            <!--TODO 分页-->
            <div class="pagination table-width-b" v-if="totalPage!=0" :style="'width:'+footWidth+';max-width:100%;'">
                <el-pagination 
                    @size-change="handleSizeChange" 
                    :page-sizes="[10,20,50]"
                    :page-size="searchForm.limit"
                    :current-page.sync="searchForm.currentPage"
                    @current-change="handleCurrentChange"
                    layout="jumper, prev, pager, next, sizes, total"
                    :total="total">
                </el-pagination>
            </div>
            <!--新增/修改弹框-->
            <el-dialog :close-on-click-modal="false" :title='titleName' v-if="addVisible" :visible.sync="addVisible" width="700px" top="20vh" :append-to-body="true" :before-close="noClick">
			    <el-form :inline="true" ref="addForm" :model="addForm" label-width="100px" :rules="addFormRules">
                    <el-form-item prop="level" label="业务级别" >
                        <el-select v-model="addForm.level" disabled size="small" clearable filterable placeholder="请选择业务级别" class="selectWdith">
                            <el-option
                            
                            v-for="(item,index) in levelOption"
                            :key="index"
                            :label="item.level_name"
                            :value="item.level">
                            </el-option>
                        </el-select>
			        </el-form-item>
                    <el-form-item prop="type" label="佣金类型">
                        <el-select disabled v-model="addForm.type" size="small" clearable filterable placeholder="请选择佣金类型" class="selectWdith">
                            <el-option
                            v-for="(item,index) in typeOption"
                            :key="index"
                            :label="item.type_name"
                            :value="item.type">
                            </el-option>
                        </el-select>
			        </el-form-item>
                    <el-form-item  label="佣金比例" prop="rate_value">
                        <el-input v-model.trim="addForm.rate_value" type="number" size="small" placeholder="请输入佣金比例" style="width: 160px;"></el-input>
						<span style="margin: 10px;font-size: 16px;">%</span>
                    </el-form-item>
                    <el-form-item label="药品剂型" prop="drug_type">
						<el-select v-model="addForm.drug_type"   size="small"  placeholder="请选择药品剂型"
							class="selectWdith">
							<el-option   v-for="item in drugTypeOptions" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
                    <el-form-item  label="备注" prop="remark">
                        <el-input v-model.trim="addForm.remark" type="textarea" :rows="5" maxlength="500" size="small" placeholder="请输入备注，限制500字符以内" style= "width: 510px;"></el-input>
                    </el-form-item>
                </el-form>
			    <span slot="footer" class="dialog-footer">
                    <el-button size="small" @click="noClick()">取&nbsp;消</el-button>
			        <el-button type="primary" size="small" @click="saveEdit('addForm',type)">保&nbsp;存</el-button>
			    </span>
			</el-dialog>
            <!--设置弹框-->
            <el-dialog :close-on-click-modal="false" :title='titleName' v-if="setVisible" :visible.sync="setVisible" width="600px" top="20vh" :append-to-body="true" :before-close="noClick">
			    <el-form :inline="true" ref="setForm" :model="setForm" label-width="100px" :rules="setFormRules">
                    <el-form-item prop="rate" label="提现比例" >
                        <el-input v-model.trim="setForm.rate" size="small" placeholder="请输入提现比例" style="width: 400px;"></el-input>
                        <div style="color:red">填写说明：0.5表示50%，0.3表示30%</div>
			        </el-form-item>
                    <el-form-item prop="remark" label="备注" >
                        <el-input v-model.trim="setForm.remark" type="textarea" :rows="5" style="width: 400px;" maxlength="500" size="small" placeholder="请输入备注，限制500字符以内"></el-input>
			        </el-form-item>
                </el-form>
			    <span slot="footer" class="dialog-footer">
                    <el-button size="small" @click="setVisible=false">取&nbsp;消</el-button>
			        <el-button type="primary" size="small" @click="updDoctCashoutById('setForm',type)">保&nbsp;存</el-button>
			    </span>
			</el-dialog>
        </div>
    </div>
</template>
<script>
import BaseUrl from '@/utils/baseUrl';
    export default {
        components: {
        },
        data() {
            var numReg=/^0\.\d+$/
        	let checkRatio=(rule, value, callback) => {

                if(value>1){
                    callback(new Error('提现比例应小于1'));
                }else if(!numReg.test(value)||value==0){
                    callback(new Error('提现比例不合法'));
                }else{
                   callback();  
                }
            }
            return {
                
                footWidth:'0px',
                loading:true,//列表loading
                tableData1:[],
                tableData2:[],
                drugTypeOptions: [],
                searchForm:{
                	level:'',
                    limit:limit,
                    currentPage:1,
                    searchType:'1',
                },
                total:0,
                totalPage:0,
                titleName:'',
                levelOption:[
                    {level_name:'一级代理',level:'1'},
                    {level_name:'二级代理',level:'2'},
                    {level_name:'三级代理',level:'3'},
                ],
                typeOption:[
                    {type_name:'直销',type:'1'},
                    {type_name:'间销',type:'2'},
                ],
                addVisible:false,
                
                addForm:{},
                
                addFormRules:{
                    level:[
                        { required: true, message: "请选择业务级别", trigger: "change" }
                    ],
                    type:[
                        { required: true, message: "请选择佣金类型", trigger: "change" }
                    ],
                    rate_value:[
                        { required: true, message: "请输入佣金比例(%)", trigger: "blur" }
                    ],
                    remark:[
                        { required: true, message: "请输入备注", trigger: "blur" }
                    ]
                },
                setForm:{
                    rate:'',
                    remark:'',
                    id:''
                },
				setFormRules:{
                    rate:[
                        { required: true, message: "请输入提现比例", trigger: "blur" },
                        { required: true,  trigger: 'blur', validator:checkRatio }
                    ],
                },
                setVisible:false,//医生设置
                type:'',//add,edit
                row:null,
                
            }
        },
        created(){
            this.getData()
            this.getList()
        },
        mounted(){
            var that=this;
            that.footWidth=that.$refs.table.bodyWidth;
        },
        methods:{
        	getList() {
				this.$http.post(BaseUrl.getDrugTypePageList, {}).then(res => {
					if (res.data.code == 0) {
						this.drugTypeOptions = []
						for (let i = 0; i < res.data.data.length; i++) {
							var obj = {
								label: '',
								value: ''
							} //用于接收被改变的对象
							obj.label = res.data.data[i].type_name
							obj.value = res.data.data[i].type_name
							this.drugTypeOptions.push(obj)
						}
					} else {
						this.$message({
							message: res.data.msg,
							type: 'warning'
						})
					}
				})
			},
            getData(){
                
                if(this.searchForm.searchType==1){
                    this.getCashoutPageList();
                }else{
                    this.getDoctCashoutPageList();
                }
            },
            //获取业务员配置列表
            getCashoutPageList(){
                this.loading=true;//列表loading
                this.$http.post(BaseUrl.getCashoutPageList,this.searchForm).then(res=>{
                    if(res.data.code==0){
                        this.tableData1 = res.data.data.data;
                        this.totalPage = res.data.data.totalPage;
                        this.total = res.data.data.total;
                        this.loading=false;//列表loading
                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        })
                    }
                    
                })
            },
            //获取医生配置列表
            getDoctCashoutPageList(){
                this.loading=true;//列表loading
                let searchForm=JSON.parse(JSON.stringify(this.searchForm))
                delete searchForm.level
                this.$http.post(BaseUrl.getDoctCashoutPageList,searchForm).then(res=>{
                    if(res.data.code==0){
                        this.tableData2 = res.data.data.data;
                        this.totalPage = res.data.data.totalPage;
                        this.total = res.data.data.total;
                        this.loading=false;//列表loading
                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        })
                    }
                    
                })
            },
            handleSizeChange(val){
                this.searchForm.limit = val;
                this.getData();
            },
            handleCurrentChange(val){
                this.searchForm.currentPage = val;
                this.getData();
            },
            reset(){
                this.resetSearchForm(this.searchForm,[{k:'limit',v:this.searchForm.limit},{k:'currentPage',v:this.searchForm.currentPage},{k:'searchType',v:this.searchForm.searchType}]);
            },
            changeSearchType(){
                this.tableData1=[];
                this.tableData2=[];
                this.resetSearchForm(this.searchForm,[{k:'limit',v:this.searchForm.limit},{k:'currentPage',v:1},{k:'searchType',v:this.searchForm.searchType}]);
                this.getData();
            },
            //显示设置弹框
            showSetVisible(row){
                this.titleName='提现设置';
                this.setForm.rate=row.rate?row.rate:''
                this.setForm.remark=row.remark?row.remark:''
                this.setForm.id=row.id;
                this.setVisible=true;
            },
            //保存医生返利
            updDoctCashoutById(form){
                this.$refs[form].validate((valid)=>{
                    if(valid){
                        this.$http.post(BaseUrl.updDoctCashoutById,this[form]).then(res=>{
                            if(res.data.code==0){
                                this.titleName='';
                                this.getData();
                                this.setVisible=false;
                                this.$message({
                                    message: res.data.msg,
                                    type: 'success'
                                })
                            }else{
                                this.$message({
                                    message: res.data.msg,
                                    type: 'warning'
                                })
                            }
                        })
                    }
                })
            },
            showAddVisible(type,row){
                this.addForm={};
                this.type=type;
                if(type=='add'){
                    this.titleName="新增提现设置";
                    this.addVisible=true;
                }else{
                    this.titleName="修改提现设置";
                    this.row=row;
                    this.$set(row,'editLoading',true);
                    this.$http.post(BaseUrl.getCashoutById,{id:row.id}).then(res=>{
                        if(res.data.code==0){
                            this.addForm=res.data.data;
                            this.addVisible=true;
                            this.$set(row,'editLoading',false);
                        }else{
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            })
                        }
                    })
                }
                
            },
            
            //保存新增/修改
            saveEdit(form,type){

                this.$refs[form].validate((valid)=>{
                    if(valid){
                        let arr=this.levelOption.filter(item=>{
                            return item.level==this[form].level;
                        })
                        this[form].level_name=arr[0].level_name;
                        if(type=='add'){
                            this.$http.post(BaseUrl.addCashoutInfo,this[form]).then(res=>{
                                if(res.data.code==0){
                                    this.titleName='';
                                    this.getData();
                                    this.addVisible=false;
                                    this.$message({
                                        message: res.data.msg,
                                        type: 'success'
                                    })
                                }else{
                                    this.$message({
                                        message: res.data.msg,
                                        type: 'warning'
                                    })
                                }
                            })
                        }else{
                            // this.addForm.id=this.row.id;
                            this.$http.post(BaseUrl.updCashoutById,this[form]).then(res=>{
                                if(res.data.code==0){
                                    this.titleName='';
                                    this.getData();
                                    this.addVisible=false;
                                    this.$message({
                                        message: res.data.msg,
                                        type: 'success'
                                    })
                                }else{
                                    this.$message({
                                        message: res.data.msg,
                                        type: 'warning'
                                    })
                                }
                            })
                        }
                        
                        
                    }
                })

            },
            deleteStation(row){
                var that=this;
                this.$confirm('是否确认删除此条的数据项?', "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(function() {
                    that.$http.post(BaseUrl.delCashout,{id:row.id}).then(res=>{
                        if(res.data.code==0){
                            that.getData();
                            that.$message({
                                message:'删除成功',
                                type:'success'
                            })
                        }else{
                            that.$message({
                                message: res.data.msg,
                                type: 'warning'
                            })
                        }
                        
                    })
                    
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
                });
            },
            noClick(){
                this.getData();
                this.addVisible=false;
                this.setVisible=false;
            },
            
        }
        
    }
</script>
<style scoped>
.selectWdith {
    width : 200px;
}
</style>